/* eslint-disable max-len */
import './slider.scss';
import Swiper from 'swiper';
import 'swiper/swiper.scss';

class Slider {
  static get toolbox() {
    return {
      title: 'Slider',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  constructor({ data }) {
    this.wrapper = undefined;
    this.slider = undefined;
    this.id = Math.floor(Math.random() * 10000);
    if (Array.isArray(data)) {
      this.data = data;
      this.lastId = Math.max(...this.data.map(({ id }) => id));
    } else {
      this.data = [];
      this.lastId = 0;
    }
  }

  render() {
    this.wrapper = document.createElement('div');
    const imageWraper = document.createElement('div');
    imageWraper.classList.add('slider-wrapper');
    this.wrapper.appendChild(imageWraper);

    this.createSlider();

    return this.wrapper;
  }

  createSlider() {
    const sliderWrapper = this.wrapper.querySelector('.slider-wrapper');
    sliderWrapper.innerHTML = `<div class="editor-slider-wrapper">
        <div class="swiper editor-slider" id="editor-slider-${this.id}">
          <div class="swiper-wrapper">
            <div class="swiper-slide last-slide">
              <div class="add-new-slide">
                Введите url:
                <div contenteditable="true" class="add-new-slide-input" name="url" placeholder="Введите адресс картинки"></div>
                <button class="add-new-slide-btn">Добавить слайд</button> 
              </div>
            </div>
          </div> 
        </div>
        <div class="swiper-button-next editor-slider-button-next"></div>
        <div class="swiper-button-prev editor-slider-button-prev"></div>
      <div>`;

    const timer = setInterval(() => {
      if (this.wrapper.querySelector(`#editor-slider-${this.id}`)) {
        clearInterval(timer);
        this.setHandlers();
        this.slider = new Swiper(`#editor-slider-${this.id}`, {
          loop: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        this.wrapper.querySelector('.editor-slider-button-prev').addEventListener('click', (e) => {
          e.stopPropagation();
          this.slider.slideNext();
        });
        this.wrapper.querySelector('.editor-slider-button-next').addEventListener('click', (e) => {
          e.stopPropagation();
          this.slider.slidePrev();
        });
        if (this.data) {
          this.data.forEach(({ url, id }) => {
            this.renderSlide(url, id);
          });
        }
      }
    }, 100);
  }

  setHandlers() {
    const btnAddSlide = this.wrapper.querySelector('.add-new-slide-btn');
    const urlAddSlide = this.wrapper.querySelector('.add-new-slide-input');
    btnAddSlide.addEventListener('click', () => {
      this.addSlide(urlAddSlide.innerText, this.lastId + 1);
    });

    this.wrapper.addEventListener('click', (e) => {
      if (e.target.classList.contains('swiper-slide-delete')) {
        this.data = this.data.filter((item) => Number(item.id) !== Number(e.target.dataset.id));
        e.target.parentElement.remove();
        this.slider.update();
      }
    });
  }

  renderSlide(url, id) {
    const slide = document.createElement('div');
    slide.classList.add('swiper-slide');

    const delBlock = document.createElement('div');
    delBlock.classList.add('swiper-slide-delete');
    delBlock.dataset.id = id;

    const image = document.createElement('img');
    image.src = url;

    slide.appendChild(image);
    slide.appendChild(delBlock);

    const lastSlide = this.wrapper.querySelector('.last-slide');

    lastSlide.before(slide);
    this.slider.update();
  }

  addSlide(url, id = 0) {
    this.renderSlide(url, id);
    this.wrapper.querySelector('.add-new-slide-input').innerText = '';
    this.data = [...this.data, { url, id }];
  }

  save() {
    return this.data;
  }
}
export default Slider;
