<template>
  <div>
    <editor
      ref="editor"
      :config="{tools, data}" />
  </div>
</template>
<script>
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Slider from '@/editor-plugins/slider/slider.js';
import Quote from '@/editor-plugins/quote/quote.js';
import SimpleImage from '@editorjs/simple-image';
import YoutubeEmbed from 'editorjs-youtube-embed';

export default {
  name: 'Editorjs',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tools: {
        image: SimpleImage,
        youtubeEmbed: YoutubeEmbed,
        header: {
          class: Header,
        },
        paragraph: {
          class: Paragraph,
          config: {
            placeholder: '.',
          },
        },
        list: {
          class: List,
        },
        Quote: {
          class: Quote,
        },
        Slider: {
          class: Slider,
        },
      },
    };
  },
  methods: {
    getValue() {
      // eslint-disable-next-line no-underscore-dangle
      this.$refs.editor._data.state.editor.save()
        .then((data) => {
          this.$emit('onSave', data);
        })
        .catch((err) => { console.error(err); });
    },
  },
};
</script>
<style lang="scss">
  .codex-editor {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
</style>
