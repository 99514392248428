<template>
  <div class="news-form">
    <el-card class="box-card">
      <el-form
        :model="form"
        label-width="120px">
        <el-form-item label="Заголовок">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="Дата-Время">
          <el-col :span="11">
            <el-date-picker
              v-model="form.date"
              type="date"
              placeholder="Дата"
              style="width: 100%" />
          </el-col>
          <el-col
            class="line"
            :span="2">
            -
          </el-col>
          <el-col :span="11">
            <el-time-picker
              v-model="form.time"
              placeholder="Время"
              style="width: 100%;" />
          </el-col>
        </el-form-item>
        <el-form-item label="Текст анонс">
          <el-input
            v-model="form.textAnnonce"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}" />
        </el-form-item>
        <el-form-item label="Картинка анонс">
          <div class="avatar-container">
            <el-upload
              action=""
              class="avatar-uploader"
              :before-upload="beforeUploadImage"
              :show-file-list="false">
              <img
                v-if="imageBase64"
                :src="imageBase64"
                class="avatar">
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="Описание">
          <editorjs
            ref="editor"
            :data="initialEditorValues"
            @onSave="sendForm" />
        </el-form-item>
        <el-form-item>
          <div class="left-text">
            <el-button
              type="primary"
              style="width: 100%"
              @click="onSubmit">
              {{ btnText }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import editorjs from '@/components/editors/newsContentEditor';

export default {
  name: 'NewsForm',
  components: {
    editorjs,
  },
  props: {
    initialFormValues: {
      type: Object,
      default: () => ({
        title: '',
        date: '',
        time: '',
        textAnnonce: '',
        image: '',
      }),
    },
    initialEditorValues: {
      type: Object,
      default: () => {},
    },
    btnText: {
      type: String,
      default: 'Добавить',
    },
  },
  data() {
    return {
      isSubmited: false,
      imageBase64: '',
      form: {
        title: 'dfsd',
        date: '',
        time: '',
        textAnnonce: '',
        image: '',
      },
    };
  },
  mounted() {
    this.form = this.initialFormValues;
  },
  methods: {
    sendForm(value) {
      const data = {
        content: value,
        ...this.form,
      };
      this.$emit('submit', data);
    },
    onSubmit() {
      this.$refs.editor.getValue();
    },
    beforeUploadImage(file) {
      const base = 1024;
      const maxImgSizeInMegabytes = 2;
      const isJPG = file.type === 'image/jpeg';
      const isLargeThenMaxImgSizeInMegabites = file.size / base / base < maxImgSizeInMegabytes;

      if (!isJPG) {
        this.$message.error('Avatar picture must be JPG format!');
      }
      if (isLargeThenMaxImgSizeInMegabites) {
        this.$message.error('Avatar picture size can not exceed 2MB!');
      }
      if (!isJPG || isLargeThenMaxImgSizeInMegabites) {
        return;
      }
      const fr = new FileReader();
      fr.onload = (() => (e) => {
        this.imageBase64 = e.target.result;
        this.form.image = file;
      })(file);
      setTimeout(() => {
        fr.readAsDataURL(file);
      });
    },
  },
};
</script>
<style lang="scss">
  .news-form {
    .avatar-container {
      text-align: left;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
</style>
